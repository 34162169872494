
import apiService from "../../config/api-config";
import { toast } from "../../utils/genericCommon";

export const storeTrafficLogs = (logs) => {
    return { type: 'SET_LOGS', payload: logs };
};

export const fetchDeviceIds = () => {
    return async (dispatch, getState) => {
        const { trafficLogsData } = getState();
        dispatch({ type: 'SET_DEVICES', payload: { ...trafficLogsData, deviceIds: [], timePeriodSelected: null } });
    };
};

export const fetchSitesIds = () => {
    return async (dispatch, getState) => {
        const { trafficLogsData } = getState();
        let siteIds = [];

        const response = await apiService.getSites();

        if ((response.errorObject instanceof Object) === true) {
            //show error toast while fetching sites failed
            toast("", "danger", 10000, response.errorObject)
        } else {
            siteIds = response.data.data;
        }

        dispatch({ type: 'SET_SITES', payload: { ...trafficLogsData, siteIds: siteIds, timePeriodSelected: null } });
    };
}

export const checkRequestExist = () => {
    return async (dispatch, getState) => {
        const { trafficLogsData } = getState();
        let existingData = null;
        let count = 0;
        let requestId = "";
        let status = "NA";
        let error = null;
        const response = await apiService.getInitialRequest();

        if ((response.errorObject instanceof Object) === true) {
            requestId = "";
            toast("", "danger", 10000, response.errorObject)
        } else {
            if (response.data.data.length > 0) {

                //request id of last request that was generated
                requestId = response.data.data[response.data.data.length - 1].req_id;
                //status of last request that was generated
                status = response.data.data[response.data.data.length - 1].status;
                const checkTrafficLogsResult = await apiService.checkTrafficLogsRequest(requestId, { offset: 0, limit: 30 });
                if ((checkTrafficLogsResult.errorObject instanceof Object) === false) {
                    existingData = checkTrafficLogsResult.data.data;
                    count = checkTrafficLogsResult.data.count;
                } else {
                    error = checkTrafficLogsResult.errorObject
                    existingData = null;
                }
            } //else no request exist and the status is can not be determined 
        }

        dispatch({
            type: 'SET_REQUEST',
            payload: {
                ...trafficLogsData,
                requestId: requestId,
                requestStatus: status,
                loader: false,
                data: existingData,
                count: count,
                error: error
            }
        });
    }
}