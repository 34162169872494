import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { FixedSizeList as List } from "react-window";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import i18n from "amdi18n-loader!../../nls/i18n";
import css from "../logsMagneticStyle.less";

const [
    HbrDropdown,
    HbrButton,
    HbrMenu,
    HbrInput,
    HbrMenuItem,
    HbrIcon,
    HbrTooltip,
    HbrCheckbox,
    HbrPortal
] = reactWrapper([
    "hbr-dropdown",
    "hbr-button",
    "hbr-menu",
    "hbr-input",
    "hbr-menu-item",
    "hbr-icon",
    "hbr-tooltip",
    "hbr-checkbox",
    "hbr-portal"
]);

const SiteDropdownCheckbox = props => {
    const [tags, setTags] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [selectedSite, setSelectedSite] = useState([]);
    const [searchedSiteList, setSearchedSiteList] = useState([]);
    const sitesRef = useRef({
        sites: []
    });

    useEffect(() => {
        const sites = props.globalFilter.sites
            .map(item => ({ value: item.site_id, label: item.site_name }));
        setSiteList(sites);
        const filteredSites = sites.slice(0);
        setSearchedSiteList(filteredSites);
    }, [props.globalFilter.sites]);

    const onSearch = e => {
        let searchText = e.target.value;
        let filteredSites = [];
        if (searchText) {
            searchText = searchText.toLowerCase();
            filteredSites = siteList.filter(
                item => item.label.toLowerCase().includes(searchText)
            );
        } else filteredSites = siteList.slice(0);
        setSearchedSiteList(filteredSites);
    };

    const onHbrSelect = (e) => {
        const site = e.target.id.replace("_", "");

        if (site) {
            if (!selectedSite.includes(site)) {
                const sites = [...selectedSite, site];
                setSelectedSite(sites);
                props.handleSiteSelect(sites);
                sitesRef.current.sites = sites;
                setTags([...tags, e.target.textContent]);
            } else {
                const index = selectedSite.indexOf(site);
                if (index > -1) {
                    selectedSite.splice(index, 1);
                }
                setSelectedSite(selectedSite);
                props.handleSiteSelect(selectedSite);
                sitesRef.current.sites = selectedSite;
                setTags([e.target.textContent]);
            }
        }
    }

    return (
        <div
            className={css["sites-dropdown-checkbox"]}
            data-cy="sites-dropdown-checkbox"
        >
            {props.globalFilter.sites.length > 0 ? (
                <div>
                    <HbrDropdown
                        menu={true}
                        multiple={true}
                        closeOnSelect={false}
                        placeholder={i18n.logs.sitesInput}
                        className="sites-dropdown"
                    >
                        <HbrButton
                            slot="trigger"
                            variant="outline"
                            sentiment="neutral"
                            placeholder={i18n.logs.sitesInput}
                            caret
                        >
                            {sitesRef.current.sites.length > 0 ?
                                `${sitesRef.current.sites.length} site(s) selected` :
                                i18n.logs.sitesInput}

                        </HbrButton>
                        <HbrMenu>
                            <div className="site-menu-container">
                                <HbrInput
                                    className="sites-container"
                                    size="medium"
                                    placeholder={i18n.megaMenuSearchSite}
                                    clearable
                                    onHbr-input={onSearch}
                                >
                                    <div slot="prefix">
                                        <HbrIcon sentiment="neutral" name="magnifying-glass" />
                                    </div>
                                </HbrInput>
                                {searchedSiteList.length > 0 ? (
                                    <List
                                        className="List"
                                        height={190}
                                        itemCount={searchedSiteList.length}
                                        itemSize={40}
                                        width={200}
                                    >
                                        {({ index, style }) => {
                                            const { label, value } = searchedSiteList[index];

                                            return (
                                                <div
                                                    className={"ListItem item-checkbox"}
                                                    style={style}
                                                >
                                                    <HbrMenuItem
                                                        key={value}
                                                        value={value}
                                                        onClick={onHbrSelect}
                                                        checked
                                                    >
                                                        <HbrCheckbox
                                                            checked={selectedSite.includes(value.toString())}
                                                            id={`_${value}`}
                                                            disabled={!selectedSite.includes(value.toString()) && selectedSite.length > 4}
                                                        >
                                                            {label}
                                                        </HbrCheckbox>
                                                        <HbrPortal>
                                                            <HbrTooltip
                                                                anchor={`#_${value}`}
                                                                placement="right"
                                                                hoist
                                                            >
                                                                <div slot="content">{label}</div>
                                                            </HbrTooltip>
                                                        </HbrPortal>
                                                    </HbrMenuItem>
                                                </div>
                                            );
                                        }}
                                    </List>
                                ) : (
                                    <div className="no-site-data hbr-css__text-secondary-sm">
                                        {i18n.logs.emptyData}
                                    </div>
                                )}
                            </div>
                        </HbrMenu>
                    </HbrDropdown>
                </div>
            ) :
                <div className="no-sites hbr-css__text-secondary-sm">
                    {i18n.logs.sitesNotFound}
                </div>
            }
        </div>
    );
};
SiteDropdownCheckbox.propTypes = {
    globalFilter: PropTypes.object.isRequired,
    handleSiteSelect: PropTypes.func.isRequired
};
export default SiteDropdownCheckbox;
