import reactWrapper from "@harbor/elements/utils/react/wrapper";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import useColumns from "@harbor/elements/utils/table/hooks/columns";
import TableStore from "@harbor/elements/utils/table/store";
import { PropTypes } from "prop-types";
import i18n from "amdi18n-loader!../../nls/i18n";
import streamSaver from 'streamsaver';
import _ from "lodash";

import OverLappingSpinner from "../../../common/Spinner/OverLappingSpinner";
import { getTableColumns } from "./trafficTableConfig";
import apiService from "../../../config/api-config";
import LogsTableView from "./TLTableView";

// default sort column and sort order
const defaultSort = ["create_time", "desc"];

//style
import css from "../logsMagneticStyle.less";
import { toast } from "../../../utils/genericCommon";

const [HbrCard, HbrIcon, HbrButton] = reactWrapper(["hbr-card", "hbr-icon", "hbr-button"]);

const TrafficLogsTable = () => {
    const state = useSelector((state) => state);
    const [tableStore, setTableStore] = useState("");
    const [status, setStatus] = useState({
        loader: true,
        error: null
    });
    const useRefFilter = useRef({
        sort: true,
        filter: null,
    })
    const tableRef = useRef({
        tableStore: tableStore,
        recordsCount: state.trafficLogsData.count
    });
    const [disabled, setDisabled] = useState(false)

    const debounce = (func, delay) => {
        let debounceTimer;
        return function (...args) {
            const context = this;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    };

    const handleSearch = debounce((e) => {
        if (e && tableRef?.current?.tableStore) {
            tableRef.current.tableStore?.setQuery({ filter: { [e.target.id]: e.target.value } });
        }
    }, 1500);

    const getExtention = (query) => {
        let filterKey = "";
        let sortingExtention = {};
        const filterArray = Object.keys(query.filter);

        if (filterArray.length > 0) {
            //filterKey is a value the user type in the column search
            //i.e filterArray[0] could be "src_ip" or "create_time" represent the column key
            filterKey = query.filter[filterArray[0]];
        }

        //generate search filter
        if (filterKey !== "") {
            useRefFilter.current.filter = {
                ...useRefFilter.current.filter,
                [`search_${filterArray[0]}`]: filterKey
            };
        }

        //generate sorting filter
        if (query?.order[0]?.length > 0) {
            //i.e. ['create_time', 'asc']
            const [colKey, sorting] = query.order[0];
            if (colKey.length > 1) {
                sortingExtention = { [`sort_${colKey}`]: sorting };
            }
        }

        if (_.isEmpty(sortingExtention) && _.isEmpty(useRefFilter.current.filter))
            return {};
        else
            return { ...sortingExtention, ...useRefFilter.current.filter };
    }
    //get the count of the records so the pagination can be set to 20 pages max
    const getCount = (count, pageLength) => {
        //if the count is greater than 600, and the page length is 30, then set the count to 600 so the pagination can be set to 20 pages
        if (pageLength === 30 && count >= 600) return 600;
        //if the count is greater than 1000, and the page length is 50, then set the count to 1000 so the pagination can be set to 20 pages
        else if (pageLength === 50 && count >= 1000) return 1000;
        //if the count is greater than 2000, and the page length is 100, then set the count to 2000 so the pagination can be set to 20 pages
        else if (pageLength === 100 && count >= 2000) return 2000;
        else return count;
    }

    useEffect(async () => {
        if (state.trafficLogsData.data) {
            let isMounted = true;
            let columns = getTableColumns(handleSearch)
            const columnsConfig = () => (columns);
            const columnsHook = useColumns({
                config: columnsConfig,
                order: defaultSort
            });
            if (isMounted) {
                let tableStore = new TableStore({
                    query: { pageLength: 30 },
                    hooks: [columnsHook],
                    fetchData: async ({ query, setSource, setCount }) => {
                        let apiExtension = getExtention(query);

                        //initialize the response to the data from the store no api call wil be made 
                        //when the user lands on the page
                        let response = query.export === undefined ? state.trafficLogsData.data : {};
                        //the block below is when the user click on the search, sort, or pagination
                        if (query.export === undefined) {
                            setStatus({
                                ...status,
                                ...{ loader: true }
                            });

                            if (_.isEmpty(apiExtension) && query.page === 0) {
                                const count = getCount(state.trafficLogsData.count, query.pageLength);

                                setCount(count, false);
                                setSource(response);
                                setStatus({
                                    ...status,
                                    ...{ loader: false }
                                });
                            } else {
                                response = await apiService.checkTrafficLogsRequest(state.trafficLogsData.requestId, { ...apiExtension, ...{ offset: query.page, limit: 30 } });

                                tableRef.current.recordsCount = response.data.count;
                                if ((response.errorObject instanceof Object) === false) {
                                    const count = getCount(response.data.count, query.pageLength);
                                    setCount(count, false);
                                    setSource(response.data.data);
                                    setStatus({
                                        ...status,
                                        ...{ loader: false }
                                    });
                                } else {
                                    toast("", "danger", 10000, response.errorObject)
                                    setStatus({
                                        ...status,
                                        ...{ loader: false }
                                    });
                                }
                            }
                        } else {
                            //the block below if when the user click on the export button
                            const toastEl = Object.assign(document.createElement("hbr-banner"), {
                                duration: "100000",
                                sentiment: "success",
                                closable: true,
                                innerHTML: i18n.logs.toastLoader,
                            });
                            document.body.appendChild(toastEl);
                            toastEl.toast();

                            const response = await apiService.exportTrafficLogsStreamRequest(state.trafficLogsData.requestId, apiExtension);

                            // Ensure response.data is a ReadableStream
                            if (!(response.data instanceof ReadableStream) ||
                                (response.errorObject instanceof Object) === true) {
                                toast("", "danger", 10000, response.errorObject)
                            } else {
                                const contentDisposition = response.headers['content-disposition'];
                                let filename = contentDisposition
                                    ? contentDisposition.split('filename=')[1].replace(/"/g, '')
                                    : 'downloaded-file.csv.gz';
                                // Replace .csv.gz with .csv
                                filename = filename.replace('.csv.gz', '.csv');

                                const writableStream = streamSaver.createWriteStream(filename);

                                // Use pipeTo to write the stream to the writable stream
                                await response.data.pipeTo(writableStream);
                            }

                            setDisabled(false);

                            const toastElement = toastEl;
                            toastElement.remove();
                        }
                        delete query.export;
                    }
                });

                setTableStore(tableStore);
                tableRef.current.tableStore = tableStore;
            }

            return () => {
                isMounted = false;
            };
        }

    }, [state.trafficLogsData])

    return (
        <HbrCard
            container
            className={css["traffic-logs-table"]}
            data-cy="traffic-logs-table"
            data-testid="traffic-log-card"
        >
            <div
                className="table-container hbr-css__layout-col-md"
                data-cy="applicationTableView"
            >
                <div className="hbr-css__layout-col-md">
                    {(state.trafficLogsData.loader === false) &&
                        <div className="search-row">
                            {state.trafficLogsData.count && <div className="table-item-count">{`${tableRef.current.recordsCount ? tableRef.current.recordsCount : state.trafficLogsData.count} ${i18n.logs.records}`}</div>}
                            <HbrButton
                                id={'hbr-export-button'}
                                data-testid="traffic-logs-export-btn"
                                variant="outline"
                                disabled={status.loader === true || disabled === true}
                                onClick={() => {
                                    setDisabled(true)
                                    tableStore.setQuery({ export: "export" })
                                }}
                            >
                                <HbrIcon slot="prefix" sentiment="interact" name="upload-simple" />
                                {i18n.logs.export}
                            </HbrButton>
                        </div>}
                </div>
                {state.trafficLogsData.loader === true || state.trafficLogsData.status === "PROCESSING" ?
                    <OverLappingSpinner extraDetailsTxt={i18n.logs.extraTime} /> :
                    <LogsTableView
                        tableStore={tableStore}
                        loader={status.loader}
                        spinnerComponent={<OverLappingSpinner extraDetailsTxt={i18n.logs.extraTime} />}
                    />
                }
            </div>
        </HbrCard >
    )
}
TrafficLogsTable.propTypes = {
    defaultSort: PropTypes.array,
    loader: PropTypes.bool
}

export default TrafficLogsTable;