import TrafficLogFilter from "./TrafficLogFilter";
import TrafficLogsTable from "./TrafficLogsTable";
import TabNavigation from "../../common/TabNavigation";
import Authorization from "../../generics/authorization";

const xValue = 0;
const wValue = 12;

const pageProps = {
    filterDashlets: [
        {
            component: TabNavigation,
            name: "tab-nav",
            layout: { x: xValue, y: 0, w: wValue, h: 5 }
        }
    ],
    dashlets: [
        {
            component: TrafficLogFilter,
            name: "filter-view",
            layout: { x: xValue, y: 0, w: wValue, h: 13 }
        },
        {
            component: TrafficLogsTable,
            name: "table-view",
            layout: { x: xValue, y: 50, w: wValue, h: 82 },
            style: { zIndex: 0 }
        }
    ]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
