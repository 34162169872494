import _ from "lodash";

import i18n from "amdi18n-loader!../../nls/i18n";
import { displayDateTime } from "../../../utils/displayTime";
import { defaultTextValue } from "../../../utils/enums";

export const getTableColumns = (handleSearch) => {
    return [
        {
            id: "create_time",
            prop: "create_time",
            name: i18n.logs.timeOccurrence,
            sortable: true,
            size: 175,
            columnTemplate: (createElement) => {

                return createElement("hbr-input", {
                    id: "create_time",
                    slot: "bottom",
                    style: {
                        "--box-bg-color": "var(--hbr-color-neutral-bg, #FFFFFF)",
                        "--width": "150px"
                    },
                    size: "small",
                    "onHbr-input": handleSearch,
                });
            },
            cellTemplate: (createElement, props) => {
                return createElement("hbr-highlight", {
                    className: "hbr-css__highlight-underline",
                    style: { height: "100%", display: "flex", alignItems: "center" },
                    text: displayDateTime(props.model.create_time)
                });
            }
        },
        {
            id: "site_name",
            prop: "site_name",
            name: i18n.logs.siteName,
            size: 120,
            sortable: true,
            columnTemplate: (createElement) => {
                return createElement("hbr-input", {
                    id: "site_name",
                    slot: "bottom",
                    style: {
                        "--box-bg-color": "var(--hbr-color-neutral-bg, #FFFFFF)",
                        "--width": "100px"
                    },
                    size: "small",
                    "onHbr-input": handleSearch,
                });
            },
            cellTemplate: (createElement, props) => {
                return createElement("hbr-highlight", {
                    className: "hbr-css__highlight-underline",
                    style: { height: "100%", display: "flex", alignItems: "center" },
                    text: !props.model.site_name ? defaultTextValue : props.model.site_name
                });
            }
        },
        {
            id: "device_host_name",
            prop: "device_host_name",
            name: i18n.logs.hostname,
            sortable: true,
            size: 100,
            columnTemplate: (createElement) => {

                return createElement("hbr-input", {
                    id: "device_host_name",
                    slot: "bottom",
                    style: {
                        "--box-bg-color": "var(--hbr-color-neutral-bg, #FFFFFF)",
                    },
                    size: "small",
                    "onHbr-input": handleSearch,
                });
            },
            cellTemplate: (createElement, props) => {
                return createElement("hbr-highlight", {
                    className: "hbr-css__highlight-underline",
                    style: { height: "100%", display: "flex", alignItems: "center" },
                    text: _.isEmpty(props.model.device_host_name) ? defaultTextValue : props.model.device_host_name
                });
            }
        },
        {
            id: "vpn_id",
            prop: "vpn_id",
            name: i18n.logs.vpnId,
            size: 120,
            sortable: true,
            columnTemplate: (createElement) => {
                return createElement("hbr-input", {
                    id: "vpn_id",
                    slot: "bottom",
                    style: {
                        "--box-bg-color": "var(--hbr-color-neutral-bg, #FFFFFF)",
                        "--width": "100px"
                    },
                    size: "small",
                    "onHbr-input": handleSearch,
                });
            },
            cellTemplate: (createElement, props) => {
                const vpnId = props.model.vpn_id

                return createElement("hbr-highlight", {
                    className: "hbr-css__highlight-underline",
                    style: { height: "100%", display: "flex", alignItems: "center" },
                    text: _.isNil(vpnId) || vpnId.toString().trim() === "" ? defaultTextValue : vpnId
                });
            }
        },
        {
            id: "src_ip",
            prop: "src_ip",
            name: i18n.logs.sourceIP,
            size: 120,
            columnTemplate: (createElement) => {

                return createElement("hbr-input", {
                    id: "src_ip",
                    slot: "bottom",
                    style: {
                        "--box-bg-color": "var(--hbr-color-neutral-bg, #FFFFFF)",
                        "--width": "90px"
                    },
                    size: "small",
                    "onHbr-input": handleSearch,
                });
            },
            cellTemplate: (createElement, props) => {
                return createElement("hbr-highlight", {
                    className: "hbr-css__highlight-underline",
                    style: { height: "100%", display: "flex", alignItems: "center" },
                    text: props.model.src_ip
                });
            }
        },
        {
            id: "dest_ip",
            prop: "dest_ip",
            name: i18n.logs.destinationIP,
            size: 120,
            columnTemplate: (createElement) => {
                return createElement("hbr-input", {
                    id: "dest_ip",
                    slot: "bottom",
                    style: {
                        "--box-bg-color": "var(--hbr-color-neutral-bg, #FFFFFF)",
                    },
                    size: "small",
                    "onHbr-input": handleSearch,
                });
            },
            cellTemplate: (createElement, props) => {
                return createElement("hbr-highlight", {
                    className: "hbr-css__highlight-underline",
                    style: { height: "100%", display: "flex", alignItems: "center" },
                    text: props.model.dest_ip
                });
            }
        },
        {
            id: "src_port",
            prop: "src_port",
            name: i18n.logs.sourcePort,
            size: 120,
            columnTemplate: (createElement) => {

                return createElement("hbr-input", {
                    id: "src_port",
                    slot: "bottom",
                    style: {
                        "--box-bg-color": "var(--hbr-color-neutral-bg, #FFFFFF)",
                        "--width": "100px"
                    },
                    size: "small",
                    "onHbr-input": handleSearch,
                });
            }
        },
        {
            id: "dest_port",
            prop: "dest_port",
            name: i18n.logs.destinationPort,
            size: 130,
            columnTemplate: (createElement) => {

                return createElement("hbr-input", {
                    id: "dest_port",
                    slot: "bottom",
                    style: {
                        "--box-bg-color": "var(--hbr-color-neutral-bg, #FFFFFF)",
                        "--width": "115px"
                    },
                    size: "small",
                    "onHbr-input": handleSearch,
                });
            }
        },
        {
            id: "fw_protocol",
            prop: "fw_protocol",
            name: i18n.logs.protocol,
            size: 100,
            columnTemplate: (createElement) => {

                return createElement("hbr-input", {
                    id: "fw_protocol",
                    slot: "bottom",
                    style: {
                        "--box-bg-color": "var(--hbr-color-neutral-bg, #FFFFFF)",
                        "--width": "80px"
                    },
                    size: "small",
                    "onHbr-input": handleSearch,
                });
            },
            cellTemplate: (createElement, props) => {
                return createElement("hbr-highlight", {
                    className: "hbr-css__highlight-underline",
                    style: { height: "100%", display: "flex", alignItems: "center" },
                    text: _.isEmpty(props.model.fw_protocol) ? defaultTextValue : props.model.fw_protocol,
                });
            }
        },
        {
            id: "application",
            prop: "application",
            name: i18n.logs.appDetails,
            size: 150,
            sortable: true,
            columnTemplate: (createElement) => {

                return createElement("hbr-input", {
                    id: "application",
                    slot: "bottom",
                    style: {
                        "--box-bg-color": "var(--hbr-color-neutral-bg, #FFFFFF)",
                        "--width": "130px"
                    },
                    size: "small",
                    "onHbr-input": handleSearch,
                });
            }
        },
        {
            id: "fw_user_name",
            prop: "fw_user_name",
            name: i18n.logs.username,
            size: 125,
            sortable: true,
            columnTemplate: (createElement) => {

                return createElement("hbr-input", {
                    id: "fw_user_name",
                    slot: "bottom",
                    style: {
                        "--box-bg-color": "var(--hbr-color-neutral-bg, #FFFFFF)",
                        "--width": "110px"
                    },
                    size: "small",
                    "onHbr-input": handleSearch,
                });
            },
            cellTemplate: (createElement, props) => {
                return createElement("hbr-highlight", {
                    className: "hbr-css__highlight-underline",
                    style: { height: "100%", display: "flex", alignItems: "center" },
                    text: _.isEmpty(props.model.fw_user_name) ? defaultTextValue : props.model.fw_user_name,
                });
            }
        },
        {
            id: "fw_class",
            prop: "fw_class",
            name: i18n.logs.ruleName,
            size: 105,
            sortable: true,
            columnTemplate: (createElement) => {

                return createElement("hbr-input", {
                    id: "fw_class",
                    slot: "bottom",
                    style: {
                        "--box-bg-color": "var(--hbr-color-neutral-bg, #FFFFFF)",
                        "--width": "90px"
                    },
                    size: "small",
                    "onHbr-input": handleSearch,
                });
            },
            cellTemplate: (createElement, props) => {
                return createElement("hbr-highlight", {
                    className: "hbr-css__highlight-underline",
                    style: { height: "100%", display: "flex", alignItems: "center" },
                    text: _.isEmpty(props.model.fw_class) ? defaultTextValue : props.model.fw_class,
                });
            }
        },
        {
            id: "fw_policy",
            prop: "fw_policy",
            name: i18n.logs.policyName,
            size: 130,
            sortable: true,
            columnTemplate: (createElement) => {
                return createElement("hbr-input", {
                    id: "fw_policy",
                    slot: "bottom",
                    style: {
                        "--box-bg-color": "var(--hbr-color-neutral-bg, #FFFFFF)",
                    },
                    size: "small",
                    "onHbr-input": handleSearch,
                });
            },
            cellTemplate: (createElement, props) => {
                return createElement("hbr-highlight", {
                    className: "hbr-css__highlight-underline",
                    style: { height: "100%", display: "flex", alignItems: "center" },
                    text: _.isEmpty(props.model.fw_policy) ? defaultTextValue : props.model.fw_policy
                });
            }
        },
        {
            id: "fw_action",
            prop: "fw_action",
            name: i18n.logs.action,
            size: 110,
            columnTemplate: (createElement) => {
                return createElement("hbr-input", {
                    id: "fw_action",
                    slot: "bottom",
                    style: {
                        "--box-bg-color": "var(--hbr-color-neutral-bg, #FFFFFF)",
                    },
                    size: "small",
                    "onHbr-input": handleSearch,
                });
            },
            cellTemplate: (createElement, props) => {
                return createElement("hbr-highlight", {
                    className: "hbr-css__highlight-underline",
                    style: { height: "100%", display: "flex", alignItems: "center" },
                    text: _.isEmpty(props.model.fw_action) ? defaultTextValue : props.model.fw_action
                });
            }
        }
    ];
};