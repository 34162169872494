import { combineReducers } from 'redux';

const activeReducer = (state = null, action) => {
    switch (action.type) {
        case 'SET_LOGS':
            return action.payload;
        case 'SET_DEVICES':
            return { ...state, deviceIds: action.payload.deviceIds };
        case 'SET_SITES':
            return { ...state, siteIds: action.payload.siteIds };
        case 'SET_REQUEST':
            return {
                ...state,
                loader: action.payload.loader,
                requestId: action.payload.requestId,
                status: action.payload.requestStatus,
                data: action.payload.data,
                count: action.payload.count
            }
        default:
            return {
                deviceIds: [],
                loader: true,
                data: null,
                timePeriodSelected: "",
                requestId: null
            };
    }
};

const rootReducer = combineReducers({
    trafficLogsData: activeReducer
});

export default rootReducer;