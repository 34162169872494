import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import React from "react";

import i18n from "amdi18n-loader!../../nls/i18n";

const [HbrTable, HbrPagination, HbrTooltip, HbrIconButton] =
    reactWrapper(["hbr-table", "hbr-pagination", "hbr-tooltip", "hbr-icon-button"]);

const LogsTableView = (props) => {
    if (!props.tableStore.state) {
        return (
            <div slot="empty" className="hbr-css__table-empty-state">
                <hbr-icon style={{ width: "85px", height: "75px" }} name="hbr-empty-state"></hbr-icon>
                <span className="hbr-css__text-secondary-sm">{i18n.logs.noMatch}</span>
            </div>
        )
    }

    const {
        source,
        columns,
        query,
        count
    } = props.tableStore.getState();

    const { pageLength, page } = query;

    return (
        <div className="sul-table">
            <HbrTable
                id="sul_table"
                data-testid="traffic-logs-table"
                serverSide={true}
                source={source}
                columns={columns}
                useClipboard={false}
                canFocus={false}
                headerSlot={true}
                style={{
                    "--header-height": "70px",
                    height: "400px",
                    width: "100%",
                }}
                onBeforesortingapply={(e) => {
                    e.preventDefault()
                    const { column, order } = e.detail;
                    if (order)
                        props.tableStore.hooks[0].sort({ order: [[column.id, order]] });
                    else
                        props.tableStore.hooks[0].sort({ order: props.defaultSort });
                }}
            >
                {source.length === 0 && (
                    <div slot="empty" className="hbr-css__table-empty-state">
                        <hbr-icon style={{ width: "85px", height: "75px" }} name="hbr-empty-state"></hbr-icon>
                        <span className="hbr-css__text-secondary-sm">{i18n.logs.noMatch}</span>
                    </div>
                )}
                {props.loader === true && <div slot="empty" className="hbr-css__table-empty-state table-spinner">
                    {props.spinnerComponent}
                </div>}
            </HbrTable>
            <div className="page-tooltip">
                <HbrTooltip content={i18n.logs.pageTooltip}>
                    <HbrIconButton noPadding={true} name="info" arialabel="information" />
                </HbrTooltip>
            </div>

            <HbrPagination
                pageSize={pageLength}
                itemCount={count}
                page={page}
                orderArray={[2, 1, 0]}
                sizeChanger={true}
                onHbr-size-changed={(e) => {
                    props.tableStore.setQuery({ pageLength: e.detail.pageSize, page: 0 });
                }}
                onHbr-page-changed={(e) => {
                    props.tableStore.setQuery({ page: e.detail });
                }}
            />
        </div>
    )
}

LogsTableView.propTypes = {
    tableStore: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    defaultSort: PropTypes.array,
    spinnerComponent: PropTypes.object,
    loader: PropTypes.bool
}

export default LogsTableView