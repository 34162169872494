import React, { useState, useEffect, useRef } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { useParams } from 'react-router-dom';

//i18n file
import i18n from "amdi18n-loader!../../nls/i18n";

//style
import css from "../logsMagneticStyle.less";

//config 
import timeFilterConfig from "../../../generics/config/time-filter-config";
import apiService from "../../../config/api-config";
import { getTrafficPayload } from "../../../config/reqConfig/trafficLogsConfig";
import { useMount } from "../../../utils/genericCommon";
import { useDispatch, useSelector } from 'react-redux';
import { storeTrafficLogs, fetchDeviceIds, fetchSitesIds, checkRequestExist } from '../actions';
import { timeFilterTypes } from "../../../utils/enums";

//utils
import { getTimePeriodELT, getFormattedUTCDate } from "../../../utils/displayTime";
import { toast } from "../../../utils/genericCommon";
import { getCookie } from "../../../serviceWorker";

//components
import TimeStamp from "../../../generics/TimeStamp";
import SiteDropdownCheckbox from "./SiteDropdownCheckbox";

import { BASE_V_MANAGE_URL } from "../../../apis/apiConstants";

const [
    HbrCard,
    HbrTabGroup,
    HbrTab,
    HbrSelect,
    HbrOption,
    HbrCheckbox,
    HbrButton,
    HbrSpinner,
    HbrModal,
    HbrDropdown,
    HbrMenuItem,
    HbrMenu
] = reactWrapper(
    [
        "hbr-card",
        "hbr-tab-group",
        "hbr-tab",
        "hbr-select",
        "hbr-option",
        "hbr-checkbox",
        "hbr-button",
        "hbr-spinner",
        "hbr-modal",
        "hbr-dropdown",
        "hbr-menu-item",
        "hbr-menu"
    ]
);

const TrafficLogFilter = () => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [isDisabledDeviceInput, setIsDisabledDeviceInput] = useState(false);
    const [isDisabledTimeInput, setIsDisabledTimeInput] = useState(false);
    const [devicesInput, setDevicesInput] = useState([]);
    const [sitesInput, setSitesInput] = useState([]);
    const [timeInput, setTimeInput] = useState("");
    const [getLogBtnPressed, setGetLogBtnPressed] = useState(false);
    const [trafficLogsData, setTrafficLogsData] = useState();
    const [retryRequest, setRetryRequest] = useState(0);
    const [countApiFailure, setCountApiFailure] = useState(0);
    const [open, setOpen] = useState(false);
    const [timeOutId, setTimeOutId] = useState(null);
    const [isLoadingDevices, setIsLoadingDevices] = useState(false);
    const state = useSelector((state) => state);
    const mount = useMount();
    const dataRef = useRef({
        devices: [],
        sites: state.trafficLogsData?.siteIds,
        listDevice: [],
        timeInput: "",
        requestId: "",
        previouslyChosenTime: null,
        previouslyChosenDevices: null
    })
    const isUIconvergence = getCookie("cl-analytics");
    const dispatch = useDispatch();
    const { trafficlogs } = useParams();
    const timeRange = Object.values(timeFilterConfig);
    timeRange.pop();
    timeRange.pop();
    timeRange.shift();
    timeRange.shift();
    timeRange.shift();

    useEffect(() => {
        dispatch(fetchSitesIds());
        dispatch(fetchDeviceIds());
        dispatch(checkRequestExist())
    }, [dispatch, trafficlogs]);

    useEffect(() => {
        if (mount.initMount === true) {
            mount.initMount = false;
        } else {
            //update table with logs
            if (trafficLogsData.status !== "CANCELED")
                dispatch(storeTrafficLogs({ ...trafficLogsData, ...{ requestId: dataRef.current.requestId } }));
        }
    }, [trafficLogsData])

    useEffect(() => {
        if (state.trafficLogsData.status === "PROCESSING") {
            setRetryRequest(Math.random() + 15000);
            setIsDisabled(true);
        }
    }, [state.trafficLogsData.status])

    useEffect(() => {
        if (retryRequest !== 0 && isDisabled === true) {
            const intervalId = setTimeout(async () => {
                const requestId = state.trafficLogsData.requestId ? state.trafficLogsData.requestId : dataRef.current.requestId;
                //2 Check on request and see if the request is ready and 
                //if not check how long we need to wait to call the chek again.
                const response = await apiService.checkTrafficLogsRequest(requestId, { offset: 0, limit: 30 });

                if ((response.errorObject instanceof Object) === false) {
                    const retryAfter = 15000;//15 seconds parseInt(result.headers["retry-after"]);

                    //if retryAfer less than a second stop the api loop
                    if (retryAfter < 1000 || response.data.status === "SUCCEEDED") {
                        endRequest(false, response.data.data, response.data.count)
                    } else {
                        //save the time for request to delay and then call after delay expire
                        //setRetryRequest(Math.random() + parseInt(result.headers["retry-after"]));
                        setRetryRequest(Math.random() + retryAfter);
                    }
                } else {
                    if (countApiFailure > 2) {
                        endRequest(response.errorObject)
                    } else {
                        setCountApiFailure(countApiFailure + 1);
                    }
                }
            }, retryRequest)
            setTimeOutId(intervalId)
        }

    }, [retryRequest, countApiFailure])

    const endRequest = (errorObject = false, data = null, count = 0) => {

        if (errorObject) {
            toast("", "danger", 10000, errorObject)
        }

        setRetryRequest(0);
        setIsDisabled(false);
        dispatch(storeTrafficLogs({
            ...trafficLogsData,
            ...{
                loader: false,
                data: data,
                deviceIds: [],
                requestId: dataRef.current.requestId,
                count: count,
                siteIds: state.trafficLogsData.siteIds,
            }
        }));
    }

    const handleGetTrafficLogs = async () => {
        let trafficRequestResult = {};
        const logsData = {
            ...state.trafficLogsData,
            ...{
                loader: true,
                data: null,
                devicesInput: devicesInput,
                timePeriodSelected: timeInput
            }
        };

        const payload = getTrafficPayload(logsData);

        dispatch(storeTrafficLogs({
            ...state.trafficLogsData,
            ...{
                loader: true
            }
        }));

        setTrafficLogsData({ ...logsData });
        setIsDisabled(true);
        setGetLogBtnPressed(true);

        //1 Initial call to get logs, that may take some time to process
        trafficRequestResult = await apiService.generateTrafficLogsRequest(payload);

        if ((trafficRequestResult.errorObject instanceof Object) === false) {

            dataRef.current.previouslyChosenTime = timeInput;
            dataRef.current.previouslyChosenDevices = devicesInput.toString();
            dataRef.current.requestId = trafficRequestResult.data.req_id;

            const intervalId = setTimeout(async () => {
                //2 Check on request and see if the request is ready and 
                //if not check how long we need to wait to call the chek again.
                const trafficLogsResult = await apiService.checkTrafficLogsRequest(dataRef.current.requestId, { offset: 0, limit: 30 });

                if ((trafficLogsResult.errorObject instanceof Object) === false) {
                    //get retry time in miliseconds
                    const retryAfter = 15000;//15 seconds parseInt(trafficLogsResult.headers["retry-after"]);

                    //if retryAfter less than a second stop the api loop
                    //populate table with data
                    if (retryAfter < 1000) {
                        endRequest();
                    } else {
                        //save the time for request to delay and then call after delay expire
                        setRetryRequest(retryAfter + Math.random())
                    }
                } else {
                    setCountApiFailure(countApiFailure + 1);
                }
            }, [5000])
            setTimeOutId(intervalId);
        } else {
            endRequest(trafficRequestResult.errorObject)
        }
    }

    const handleCancel = async () => {
        setOpen(true);
    }

    const handleSiteSelect = (site) => {
        if (site) {
            setSitesInput(site);
            setIsDisabledDeviceInput(true);
            if (timeInput && site.length > 0) {
                setIsDisabledTimeInput(true);
                getDevices(timeInput, site);
            } else {
                setIsDisabledTimeInput(false);
                setDevicesInput([]);
            }
        } else {
            setDevicesInput([]);
        }
    }

    const getDevices = (selectedTime, sites) => {
        const timeRange = getTimePeriodELT(timeFilterTypes[selectedTime])
        const [startDate, endDate] = timeRange.current_period;
        setIsLoadingDevices(true)

        apiService.getTopDevices({
            entry_ts: [
                getFormattedUTCDate(startDate - (12 * 3600000)),
                getFormattedUTCDate(endDate)
            ],
            time_frame: timeFilterTypes[selectedTime],
            site_id: sites.map((input) => parseInt(input))
        }).then(res => {
            if ((res.errorObject instanceof Object) === false) {

                dataRef.current.listDevice = res.data.data.map((device) => {
                    return {
                        text: device.local_host_name,
                        value: device.local_system_ip,
                        site: device.site_name
                    }
                })
                dispatch(storeTrafficLogs({
                    ...state.trafficLogsData,
                    ...{
                        loader: false,
                        deviceIds: dataRef.current.listDevice,
                        siteIds: state.trafficLogsData.siteIds,
                    }
                }));
            } else {
                //show error toast while fetching sites failed
                toast("", "danger", 10000, res.errorObject)
                setIsDisabledDeviceInput(false);
            }
            setIsLoadingDevices(false)
        });
    }

    const handleTimeInput = (e) => {
        const value = e.detail.item.value;
        dataRef.current.timeInput = value;
        setTimeInput(value);

        if (sitesInput.length > 0 && value !== "") {
            setIsDisabledTimeInput(true);
            getDevices(value, sitesInput);
        } else
            setIsDisabledTimeInput(false);
    }

    const handleDeviceInput = (evt) => {
        setDevicesInput(evt.target.value);
    }

    const handleModalClose = () => {
        setOpen(false);
    };

    const handleModalOk = async () => {
        const requestId = dataRef.current.requestId ? dataRef.current.requestId : state.trafficLogsData.requestId;
        //hnadle cancel request
        const response = await apiService.cancelTrafficLogs(requestId);

        const deviceIds = trafficLogsData?.deviceIds ? trafficLogsData?.deviceIds : state.trafficLogsData.devicesInput;

        //check if cancel api fails
        if (response.errorObject instanceof Object === true) {
            toast("", "danger", 10000, response.errorObject)
        }
        setRetryRequest(0);
        setIsDisabled(false);
        dispatch(storeTrafficLogs({
            ...state.trafficLogsData,
            ...{
                requestId: "",
                loader: false,
                data: null,
                deviceIds: deviceIds,
                status: "CANCELED"
            }
        }));
        setOpen(false);
        if (timeOutId) clearInterval(timeOutId);
    }

    const getOption = (ids, option) => {
        return (
            <HbrOption
                value={option["value"]}
                key={option["value"]}
            >
                {option["value"] !== "not_found" ?
                    <HbrCheckbox
                        disabled={ids.length > 4 && !ids.includes(option["value"])}
                        onHbr-change={(event) => event.stopPropagation()}
                        checked={ids.includes(option["value"])}
                    >
                        <div id={`id_${option["value"]?.toString()?.replaceAll(".", "_")}`}>{option["text"]} </div>
                    </HbrCheckbox> : <HbrOption disabled>{"Device(s) not found"}</HbrOption>}
            </HbrOption>
        )
    }

    const getIds = (ids) => {
        return ids.map((option) => {
            return getOption(devicesInput, option);
        });
    }

    const handleLogsTabChange = (e) => {
        if (e.target.tagName === "HBR-TAB") {
            const selectedPanel = e.target.panel;
            const tabMapping = {
                alarms: 'Alarms',
                events: 'Events',
                audit: 'Audit Logs',
                acl: 'ACL Logs'
            };
            const userActiveTab = tabMapping[selectedPanel];
            if (userActiveTab) {
                sessionStorage.setItem('userActiveTab', userActiveTab);
                window.location.href = `${BASE_V_MANAGE_URL}/logs`;
            }
        }
    };

    return (
        <HbrCard
            container
            className={css["logs-widget"]}
            data-cy="log-widget"
            data-testid="traffic-logs-filter-card"
        >
            <div className="title-row">
                <div className="hbr-type-h2 logs-title">{i18n.logs.label}</div>
                {timeInput &&
                    <TimeStamp
                        hideCompareText={true}
                        globalFilter={{
                            timePeriodSelected: timeInput, timeFilter: getTimePeriodELT(timeInput)
                        }}
                    />
                }
            </div>
            <HbrTabGroup size="small" onHbr-click={handleLogsTabChange} data-testid="traffic-logs-tab-group">
                {isUIconvergence ?
                    <>
                        <HbrTab slot="nav" panel="alarms">
                            {i18n.logs.alarms}
                        </HbrTab>
                        <HbrTab slot="nav" panel="events">
                            {i18n.logs.events}
                        </HbrTab>
                        <HbrTab slot="nav" panel="audit">
                            {i18n.logs.audit}
                        </HbrTab>
                        <HbrTab slot="nav" panel="acl">
                            {i18n.logs.acl}
                        </HbrTab>
                        <HbrTab slot="nav" panel="traffic" active={true}>
                            {i18n.logs.traffic}
                        </HbrTab>
                    </>
                    :
                    <HbrTab slot="nav" panel="traffic" active={true}>
                        {i18n.logs.traffic}
                    </HbrTab>}
            </HbrTabGroup>
            <div className="hbr-type-h3 logs-subtitle">{i18n.logs.setCriteria}</div>
            <div className="traffic">
                <div className="traffic-select-fields">
                    {/** Selected sites input */}
                    {!state.trafficLogsData.siteIds ?
                        <HbrButton disabled={true} variant={"outline"} sentiment="neutral">
                            <HbrSpinner slot="prefix" />
                            {i18n.logs.fetchSites}
                        </HbrButton> :
                        <SiteDropdownCheckbox
                            {...{ globalFilter: { sites: state.trafficLogsData.siteIds } }}
                            handleSiteSelect={handleSiteSelect}
                        />}
                    {/** Time range input */}
                    <div className=" traffic-select-fields-time">
                        <HbrDropdown
                            value={timeInput}
                            menu={true}
                            placeholder={i18n.logs.timeRange}
                            className="time-dropdown"
                        >
                            <HbrButton
                                slot="trigger"
                                variant="outline"
                                sentiment="neutral"
                                caret
                            >
                                {timeInput ? timeFilterConfig[timeInput].label : i18n.logs.timeRange}
                            </HbrButton>
                            <HbrMenu onHbr-select={handleTimeInput}>
                                {timeRange.map((time) => (
                                    <HbrMenuItem value={time.value} key={time.label}>{time.label}</HbrMenuItem>
                                ))}
                            </HbrMenu>
                        </HbrDropdown>
                    </div>

                    {/** Selected device input */}
                    {isLoadingDevices === true ?
                        <HbrButton disabled={true} variant={"outline"} sentiment="neutral" data-testid="device-input-btn">
                            <HbrSpinner slot="prefix" />
                            {i18n.logs.fetchDevices}
                        </HbrButton> :
                        <div>
                            <HbrSelect
                                placeholder={i18n.logs.selectedDevices}
                                name="tag"
                                onHbr-change={handleDeviceInput}
                                clearable={true}
                                variant={"tag"}
                                data-testid="device-input-selector"
                                value={devicesInput}
                                disabled={isDisabled === true || isDisabledDeviceInput === false || isDisabledTimeInput === false}
                            >
                                <span slot="counter" id="select-multi-counter">
                                    {devicesInput.length}
                                </span>
                                {state.trafficLogsData.deviceIds && getIds(state.trafficLogsData.deviceIds)}
                            </HbrSelect>
                        </div>}
                    {/** Selected GetLogs button */}
                    <div className="traffic-select-fields-time">
                        <HbrButton
                            disabled={devicesInput.length === 0 || isDisabled === true}
                            onClick={handleGetTrafficLogs}
                            data-testid="get-logs-btn"
                        >
                            {isDisabled === true && <HbrSpinner slot="prefix" />}
                            {i18n.logs.getLogs}
                        </HbrButton>
                    </div>
                    {/** Selected Cancel button */}
                    {isDisabled === true &&
                        <div className="traffic-select-fields-time">
                            <HbrButton onClick={handleCancel}>
                                {i18n.logs.cancel}
                            </HbrButton>
                        </div>}
                </div>
                {getLogBtnPressed === false && <div className="no-saved-input-filters"></div>}
            </div>
            {open === true &&
                <HbrModal label="Warning" open={open} data-testid="traffic-logs-warning-modal">
                    <div>
                        {i18n.logs.cancelRequest}
                    </div>
                    <div
                        slot="footer"
                        className="hbr-css__layout-row-md hbr-css__layout-justify-end"
                    >
                        <HbrButton variant="text" onClick={handleModalClose}>
                            {i18n.logs.cancel}
                        </HbrButton>
                        <HbrButton variant="fill" onClick={handleModalOk}>
                            {i18n.logs.ok}
                        </HbrButton>
                    </div>
                </HbrModal>
            }
        </HbrCard>
    )
}

TrafficLogFilter.propTypes = {}

export default TrafficLogFilter;